import $ from 'jquery'

export default class resultEditForm {
  constructor (element) {
    this.$form = $(element)

    this.$resultSelect = this.$form.find('select#ergebnis')

    this.$ctValueInput = this.$form.find('input#ctvalue')
    this.$ctValueInput2 = this.$form.find('input#ctvalue2')
    this.$ctValueInput3 = this.$form.find('input#ctvalue3')
    this.$ctValueRow = this.$ctValueInput.closest('.formRow')
    this.$ctValueRow2 = this.$ctValueInput2.closest('.formRow')
    this.$ctValueRow3 = this.$ctValueInput3.closest('.formRow')

    this.$resultSelect.on('change', this.handleErgebnisChange.bind(this))

    this.handleErgebnisChange()
  }

  handleErgebnisChange (event) {
    const val = this.$resultSelect.val()
    if (val === 'Positiv') {
      this.$ctValueRow.show()
      this.$ctValueRow2.show()
      this.$ctValueRow3.show()
    } else {
      this.$ctValueRow.hide()
      this.$ctValueRow2.hide()
      this.$ctValueRow3.hide()
    }
  }
}
