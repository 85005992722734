import $ from 'jquery'

$(function ($) {
  $("input[jQtype='passwordSwitchable']").each(function () {
    const $field = $(this)
    const $container = $field.closest('.passwordSwitchable')
    const $button = $container.find('.togglePassword')

    const $passwordVisible = $button.find('.passwordVisible')
    const $passwordInvisible = $button.find('.passwordInvisible')
    $passwordInvisible.hide()

    $button.on('click', function (event) {
      event.preventDefault()
      const type = $field.attr('type')
      switch (type) {
        case 'text':
          $field.attr('type', 'password')
          $passwordInvisible.hide()
          $passwordVisible.show()
          break
        default:
          $field.attr('type', 'text')
          $passwordInvisible.show()
          $passwordVisible.hide()
          break
      }
    })
  })
})
