import Behavior from '../behaviors'
import $ from 'jquery'

export default class dashboard {
  constructor (element) {
    this.$widgets = $('.widget', element)

    this.$loadingSpinner = $('.loadingSpinner', element)

    this.$dashboardFilter = $('.dashboardFilter', element)
    this.$dashboardFilter.on('click', this.handleDashboardFilter.bind(this))

    this.$filterAllButton = $('.quickFilter button.selectAll', element)
    this.$filterAllButton.on('click', this.handleSelectStandorteAll.bind(this))

    this.$filterNoneButton = $('.quickFilter button.selectNone', element)
    this.$filterNoneButton.on('click', this.handleSelectStandorteNone.bind(this))

    this.$standortListCheckboxes = $('.standortSelection .standortList .form-check')

    this.$widgets.each(function (index, item) {
      this.initializeWidget($(item))
    }.bind(this))
  }

  handleDashboardFilter (event) {
    event.stopPropagation()
  }

  handleSelectStandorteAll (event) {
    event.preventDefault()
    this.handleSelectStandorte(true)
  }

  handleSelectStandorteNone (event) {
    event.preventDefault()
    this.handleSelectStandorte(false)
  }

  handleSelectStandorte (state) {
    this.$standortListCheckboxes.filter('.hidden').find('input[type=checkbox]').prop('checked', false)
    this.$standortListCheckboxes.not('.hidden').find('input[type=checkbox]').prop('checked', state)
  }

  initializeWidget ($widgetContainer) {
    const widgetUrl = $widgetContainer.data('widgetUrl')
    $widgetContainer.addClass('loading')
    $widgetContainer.html(this.$loadingSpinner.html())

    $.get(widgetUrl).done(function (response) {
      $widgetContainer.removeClass('loading')
      $widgetContainer.html(response)
      Behavior.attachBehaviors($widgetContainer.get(0))
    })
  }
}
