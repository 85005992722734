import $ from 'jquery'

export default class websiteTranslations {
  constructor (element) {
    this.$editor = $(element)

    this.$textareas = $('textarea.autosize', element)
    this.$textareas.on('change', this.handleTextareaChange.bind(this))

    this.$editorCollapsibleContainer = $('.editorCollapsibleContainer', element)
    this.$editorCollapsibleLink = $('.editorCollapsibleContainer .editorCollapsibleLink', element)
    this.$editorCollapsibleLink.on('click', this.handleEditorCategoryClick.bind(this))
  }

  handleEditorCategoryClick (event) {
    const $editorCollapsibleContainer = $(event.target).closest('.editorCollapsibleContainer')
    $editorCollapsibleContainer.toggleClass('open')
  }

  handleTextareaChange (event) {
    const $textarea = $(event.target)
    if ($textarea.val() !== '') {
      $textarea.removeClass('empty')
      if ($textarea.val() !== $textarea.data('oldVal')) {
        $textarea.addClass('changed')
      } else {
        $textarea.removeClass('changed')
      }
    } else {
      $textarea.addClass('empty')
    }
  }
}
