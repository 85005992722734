import $ from 'jquery'

export default class standortSelectionQuickFilter {
  constructor (element) {
    this.$element = $(element)

    this.$standortList = $('.standortList', element)
    this.$items = this.$standortList.find('.form-check')

    this.$quickSearch = $('input#quickSearch', element)
    this.$quickCountry = $('select#quickCountry', element)

    this.$quickSearch.on('keyup', this.handleQueryChange.bind(this))
    this.$quickSearch.on('change', this.handleQueryChange.bind(this))
    this.$quickCountry.on('change', this.handleCountryChange.bind(this))

    this.filter = {
      query: '',
      country: ''
    }

    this.handleQueryChange()
    this.handleCountryChange()
  }

  handleQueryChange () {
    this.setFilterQuery(this.$quickSearch.val())
  }

  handleCountryChange () {
    this.setFilterCountry(this.$quickCountry.val())
  }

  setFilterQuery (query) {
    this.filter.query = query.toLowerCase().trim()
    this.filterSites()
  }

  setFilterCountry (country) {
    this.filter.country = country.toLowerCase().trim()
    this.filterSites()
  }

  filterSites () {
    this.$items.each(function (idx, element) {
      const $item = $(element)
      const query = $item.data('query')
      const country = $item.data('country')

      if ((this.filter.query === '' || query.indexOf(this.filter.query) !== -1) &&
                (this.filter.country === '' || country === this.filter.country)) {
        $item.removeClass('hidden')
      } else {
        $item.addClass('hidden')
      }
    }.bind(this))
  }
}
