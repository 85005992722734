import $ from 'jquery'

export default class testTypenSelect {
  constructor (element) {
    this.$selectAllTestcenterDE = $('input#select_all_DE', element)
    this.$selectAllTestcenterDE.click(function () {
      $("#DE input[type='checkbox']").prop('checked', this.checked)
    })

    this.$selectAllTestcenterES = $('input#select_all_ES', element)
    this.$selectAllTestcenterES.click(function () {
      $("#ES input[type='checkbox']").prop('checked', this.checked)
    })

    this.$selectAllFirmenDE = $('input#select_all_firmenDE', element)
    this.$selectAllFirmenDE.click(function () {
      $("#FirmenDE input[type='checkbox']").prop('checked', this.checked)
    })

    this.$selectAllFirmenES = $('input#select_all_firmenES', element)
    this.$selectAllFirmenES.click(function () {
      $("#FirmenES input[type='checkbox']").prop('checked', this.checked)
    })

    this.$selectLaborBaWu = $('input#select_labor_BaWu', element) // BAWÜ
    this.$selectLaborBaWu.click(function () {
      $('#standort20').prop('checked', this.checked) // Tübingen
      $('#standort78').prop('checked', this.checked) // Kirchheim A 8
      $('#standort192').prop('checked', this.checked) // Kirchheim Rosmarkt (Stadtmitte)
      $('#standort389').prop('checked', this.checked) // Burgau
      $('#standort450').prop('checked', this.checked) // Neu Ulm
      $('#standort249').prop('checked', this.checked) // Legoland
      $('#standort19').prop('checked', this.checked) // Stuttgart Bosch Areal
      $('#standort14').prop('checked', this.checked) // Karlsruhe
      $('#standort250').prop('checked', this.checked) // Europapark
      $('#standort114').prop('checked', this.checked) // Lahr
      $('#standort95').prop('checked', this.checked) // Offenburg Marktplatz
      $('#standort27').prop('checked', this.checked) // Offenburg Etage Eins
      $('#standort3').prop('checked', this.checked) // Darmstadt Heidelberger
      $('#standort189').prop('checked', this.checked) // Darmstadt Stadtmitte
      $('#standort414').prop('checked', this.checked) // DE - Safemeditec - Heidelberg Medizinische Klinik
      $('#standort415').prop('checked', this.checked) // DE - Safemeditec - Heidelberg Kinderklinik
      $('#standort416').prop('checked', this.checked) // DE - Safemeditec - Heidelberg Kopfklinik
      $('#standort422').prop('checked', this.checked) // DE - Safemeditec - Orthopädie Heidelberg
      $('#standort423').prop('checked', this.checked) // DE - Safemeditec - Heidelberg Universitätsplatz
      $('#standort366').prop('checked', this.checked) // Mannheim Nationaltheater
      $('#standort25').prop('checked', this.checked) // Mannheim Fernmeldeturm
      $('#standort451').prop('checked', this.checked) // Aalen Rewe
      $('#standort467').prop('checked', this.checked) // DE - Neckartenzlingen - Hirschmann
      $('#standort468').prop('checked', this.checked) // DE - Ichenhausen - Freibad
      $('#standort466').prop('checked', this.checked) // DE - Heidelberg - Eppelheim
      $('#standort489').prop('checked', this.checked) // DE - Heidelberg - VeniceBeach
      $('#standort497').prop('checked', this.checked) // DE - Ludwigshafen - Edeka Oggersheim
      $('#standort495').prop('checked', this.checked) // DE - Frankenthal - Bahnhofsstraße 5 (neben der Sparkasse)
      $('#standort215').prop('checked', this.checked) // DE - Worms - Wormser Einkaufs Park
      $('#standort478').prop('checked', this.checked) // DE - Worms - Obermarkt
      $('#standort530').prop('checked', this.checked) // DE - Darmstadt  - Eissporthalle
      $('#standort115').prop('checked', this.checked) // DE - Darmstadt - Bismarckstraße
      $('#standort418').prop('checked', this.checked) // DE - Darmstadt - TU Studierenden Test Stadtmitte
      $('#standort421').prop('checked', this.checked) // DE - Darmstadt - TU Studierenden Test Lichtwiese
      $('#standort190').prop('checked', this.checked) // DE - Darmstadt - Universität Lichtwiese
      $('#standort452').prop('checked', this.checked) // DE - Darmstadt - Messplatz
      $('#standort217').prop('checked', this.checked) // DE - Darmstadt - Hochschule
      $('#standort491').prop('checked', this.checked) // DE - Darmstadt - Staatstheater
      $('#standort492').prop('checked', this.checked) // DE - Darmstadt - Klinikum
      $('#standort509').prop('checked', this.checked) // DE - Darmstadt - SG Arheilgen
      $('#standort529').prop('checked', this.checked) // DE - Günzburg  - Diskothek Puls
      $('#standort531').prop('checked', this.checked) // DE - Lindau - Bodensee
    })

    this.$selectLaborNordHessen = $('input#select_labor_NordHessen', element) // Nord Hessen
    this.$selectLaborNordHessen.click(function () {
      $('#standort453').prop('checked', this.checked) // Idstein
      $('#standort446').prop('checked', this.checked) // Limburg
      $('#standort11').prop('checked', this.checked) // Gießen
      $('#standort94').prop('checked', this.checked) // Wetzlar
      $('#standort123').prop('checked', this.checked) // Dillenburg
      $('#standort13').prop('checked', this.checked) // Herborn
      $('#standort183').prop('checked', this.checked) // Eckelshausen
      $('#standort253').prop('checked', this.checked) // Kirchhain - Festplatz
      $('#standort16').prop('checked', this.checked) // Marburg Aquamar
      $('#standort15').prop('checked', this.checked) // Kassel Struthbachweg
      $('#standort212').prop('checked', this.checked) // Kassel Bettenhausen
      $('#standort463').prop('checked', this.checked) // DE - Wetzlar - Lahn Dill Klinik
    })

    this.$selectLaborMZWI = $('input#select_labor_MZWI', element) // MZ/WI
    this.$selectLaborMZWI.click(function () {
      $('#standort456').prop('checked', this.checked) // Ingelheim - Parkplatz Bauhof - neben Polizeistation
      $('#standort472').prop('checked', this.checked) // Ingelheim - West am Mehrgenerationenhaus
      $('#standort220').prop('checked', this.checked) // Rüdesheim
      $('#standort426').prop('checked', this.checked) // DE - Safemeditec - Mainz
      $('#standort2').prop('checked', this.checked) // Mainz HBF
      $('#standort219').prop('checked', this.checked) // Mainz GBC
      $('#standort118').prop('checked', this.checked) // Mainz Gonsenheim
      $('#standort1').prop('checked', this.checked) // Wiesbaden Schierstein XXLutz
      $('#standort66').prop('checked', this.checked) // Wiesbaden - Sonnenberg
      // Safemeditec Wiesbaden missing
      $('#standort399').prop('checked', this.checked) // Wiesbaden Nordenstadt
      $('#standort474').prop('checked', this.checked) // DE - Bad Kreuznach / Roxheim - Aral Tankstelle
      $('#standort516').prop('checked', this.checked) // DE - Gau Algesheim - Aral Tankstelle
      $('#standort473').prop('checked', this.checked) // DE - Nieder-Olm - am Cleverfit
    })

    this.$selectLaborFFM = $('input#select_labor_FFM', element) // PCR NORDOST / UPS
    this.$selectLaborFFM.click(function () {
      $('#standort213').prop('checked', this.checked) // Dreieich
      $('#standort71').prop('checked', this.checked) // Heusenstamm
      $('#standort76').prop('checked', this.checked) // Rodgau
      $('#standort437').prop('checked', this.checked) // Frankfurt am Main - Berger Straße
      $('#standort438').prop('checked', this.checked) // Frankfurt am Main - Hauptbahnhof
      $('#standort4').prop('checked', this.checked) // Frankfurt Henschelstraße
      $('#standort461').prop('checked', this.checked) // Hanau -  Hauptbahnhof
      $('#standort460').prop('checked', this.checked) // Hanau - Kurt-Schumacher-Platz
    })

    this.$selectLaborNRW = $('input#select_labor_NRW', element) // NRW
    this.$selectLaborNRW.click(function () {
      $('#standort85').prop('checked', this.checked) // Jüchen
      $('#standort103').prop('checked', this.checked) // Bergkamen
      $('#standort181').prop('checked', this.checked) // Kamen
      $('#standort102').prop('checked', this.checked) // Unna
      $('#standort17').prop('checked', this.checked) // Münster Hawerkamp
      $('#standort18').prop('checked', this.checked) // DE - Paderborn - Paderhalle
      $('#standort77').prop('checked', this.checked) // Delbrück
      $('#standort70').prop('checked', this.checked) // DE - Hövelhof
      $('#standort86').prop('checked', this.checked) // Geseke
      $('#standort79').prop('checked', this.checked) // Sankt Augustin - Haus der nNchbarschaft
      $('#standort109').prop('checked', this.checked) // DE - Sankt Augustin - Rathaus
      $('#standort52').prop('checked', this.checked) // Bonn Brückenforum
      $('#standort202').prop('checked', this.checked) // Köln Mediapark
      $('#standort157').prop('checked', this.checked) // Köln - Porz - Eltzhof
      $('#standort9').prop('checked', this.checked) // Essen
      $('#standort128').prop('checked', this.checked) // Dortmund
      $('#standort454').prop('checked', this.checked) // DE - Dortmund - U-Turm
      $('#standort517').prop('checked', this.checked) // DE - Paderborn - Capitol
      $('#standort180').prop('checked', this.checked) // DE - Münster - Hiltrup
    })

    this.$selectLaborOST = $('input#select_labor_OST', element) // OST
    this.$selectLaborOST.click(function () {
      $('#standort7').prop('checked', this.checked) // Dresden
      $('#standort166').prop('checked', this.checked) // Chemnitz
      $('#standort22').prop('checked', this.checked) // Zwickau
      $('#standort12').prop('checked', this.checked) // Görlitz
      $('#standort168').prop('checked', this.checked) // DE - Potsdam - Science Park
      $('#standort169').prop('checked', this.checked) // DE - Potsdam - Treffpunkt-Freizeit
      $('#standort170').prop('checked', this.checked) // DE - Potsdam - Stern-Center
      $('#standort434').prop('checked', this.checked) // DE - Potsdam - Campus Griebnitzsee | Haus 1
      $('#standort435').prop('checked', this.checked) // DE - Potsdam - Am Neuen Palais
      $('#standort204').prop('checked', this.checked) // DE - Potsdam - Porta Möbel
      $('#standort207').prop('checked', this.checked) // DE - Potsdam - Schiffbauergasse
      $('#standort211').prop('checked', this.checked) // DE - Potsdam - Dorint Hotel
      $('#standort477').prop('checked', this.checked) // DE - Ilmenau - Universität
      $('#standort481').prop('checked', this.checked) // DE - Saalfeld - Feuerwehr
    })

    this.$selectLaborMedicare = $('input#select_labor_medicare', element) // OST
    this.$selectLaborMedicare.click(function () {
      $('#standort551').prop('checked', this.checked) // DE - Bonn - Friedensplatz
      $('#standort552').prop('checked', this.checked) // DE - Bonn - Remigiusplatz
      $('#standort553').prop('checked', this.checked) // DE - Brühl - Gieslergalerie
      $('#standort554').prop('checked', this.checked) // DE - Duisburg - Uni
      $('#standort555').prop('checked', this.checked) // DE - Essen - Uni
      $('#standort557').prop('checked', this.checked) // DE - Frankfurt am Main - Flughafen
      $('#standort558').prop('checked', this.checked) // DE - Hennef
      $('#standort559').prop('checked', this.checked) // DE - Köln/Bonn - Flughafen
      $('#standort560').prop('checked', this.checked) // DE - Köln - Deutz
      $('#standort561').prop('checked', this.checked) // DE - Köln - Ebertplatz
      $('#standort562').prop('checked', this.checked) // DE - Köln - Marsdorf
      $('#standort563').prop('checked', this.checked) // DE - Köln - Neumarkt
      $('#standort564').prop('checked', this.checked) // DE - Köln - Pferderennbahn
      $('#standort565').prop('checked', this.checked) // DE - Siegburg
      $('#standort566').prop('checked', this.checked) // DE - Troisdorf
    })

    this.select_labor_zb_potsdam = $('input#select_labor_zb_potsdam', element) // OST
    this.select_labor_zb_potsdam.click(function () {
      $('#standort168').prop('checked', this.checked) // DE - Potsdam - Science Park
      $('#standort207').prop('checked', this.checked) // DE - Potsdam - Schiffbauergasse
      $('#standort435').prop('checked', this.checked) // DE - Potsdam - Am Neuen Palais
      $('#standort434').prop('checked', this.checked) // DE - Potsdam - Campus Griebnitzsee | Haus 1
    })

    this.select_labor_zb_bayern = $('input#select_labor_zb_bayern', element) // OST
    this.select_labor_zb_bayern.click(function () {
      $('#standort389').prop('checked', this.checked) // Burgau
      $('#standort425').prop('checked', this.checked) // DE - Siemens Healthineers - Ansbach
      $('#standort529').prop('checked', this.checked) // DE - Günzburg  - Diskothek Puls
      $('#standort468').prop('checked', this.checked) // DE - Ichenhausen - Freibad
      $('#standort450').prop('checked', this.checked) // Neu Ulm
      $('#standort451').prop('checked', this.checked) // Aalen Rewe
      $('#standort531').prop('checked', this.checked) // DE - Lindau - Bodensee
    })

    this.select_labor_zb_rhein_neckar = $('input#select_labor_zb_rhein_neckar', element) // OST
    this.select_labor_zb_rhein_neckar.click(function () {
      $('#standort478').prop('checked', this.checked) // DE - Worms - Obermarkt
      $('#standort495').prop('checked', this.checked) // DE - Frankenthal - Bahnhofsstraße 5 (neben der Sparkasse)
      $('#standort497').prop('checked', this.checked) // DE - Ludwigshafen - Edeka Oggersheim
    })

    this.select_labor_zb_offenburg = $('input#select_labor_zb_offenburg', element) // OST
    this.select_labor_zb_offenburg.click(function () {
      $('#standort250').prop('checked', this.checked) // Europapark
      $('#standort27').prop('checked', this.checked) // Offenburg Etage Eins
      $('#standort114').prop('checked', this.checked) // Lahr
    })

    this.select_labor_zb_darmstadt = $('input#select_labor_zb_darmstadt', element) // OST
    this.select_labor_zb_darmstadt.click(function () {
      $('#standort530').prop('checked', this.checked) // DE - Darmstadt  - Eissporthalle
      $('#standort190').prop('checked', this.checked) // DE - Darmstadt - Universität Lichtwiese
      $('#standort217').prop('checked', this.checked) // DE - Darmstadt - Hochschule
      $('#standort492').prop('checked', this.checked) // DE - Darmstadt - Klinikum
    })

    this.select_labor_zb_rhein_nahe = $('input#select_labor_zb_rhein_nahe', element) // OST
    this.select_labor_zb_rhein_nahe.click(function () {
      $('#standort473').prop('checked', this.checked) // DE - Nieder-Olm - am Cleverfit
      $('#standort456').prop('checked', this.checked) // Ingelheim - Parkplatz Bauhof - neben Polizeistation
      $('#standort472').prop('checked', this.checked) // Ingelheim - West am Mehrgenerationenhaus
      $('#standort474').prop('checked', this.checked) // DE - Bad Kreuznach / Roxheim - Aral Tankstelle
    })
  };
}
