import $ from 'jquery'

export default class userProfile {
  constructor (element) {
    this.$page = $(element)

    this.$2faSettings = $('.2faSettings', element)
    this.$use2faCheckbox = $('input#use2fa', element)

    this.$use2faCheckbox.on('change', this.handleCheckboxChange.bind(this))
    this.setVisibilityOf2FaSettings()
  }

  handleCheckboxChange (event) {
    this.setVisibilityOf2FaSettings()
  }

  setVisibilityOf2FaSettings () {
    const state = this.$use2faCheckbox.prop('checked')
    this.$2faSettings.toggle(state)
  }
}
