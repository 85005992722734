import $ from 'jquery'
import './bootstrap'

import './tinyMce'

import './emojioneArea'
import './cmsTools'

import './jQtype/dateTimePicker'
import './jQtype/json'
import './jQtype/autosize'
import './jQtype/passwordSwitchable'

import Behaviors from './behaviors'

$(document).ready(function () {
  Behaviors.attachBehaviors(document)
})
