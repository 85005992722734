import ChartJs from 'chart.js'
import $ from 'jquery'

export default class dashboardWidget {
  constructor (element) {
    this.$widget = $(element)
    this.$charts = $('.chart', element)
    this.$charts.each(function (index, item) {
      this.initializeChart($(item))
    }.bind(this))
  }

  initializeChart ($chart) {
    const $ctx = $chart.find('canvas')
    const chartData = $chart.data('chartData')
    const chartType = $chart.data('chartType')
    const options = this.getOptionsForType($chart.data('chartOption'))

    options.title.text = $chart.data('chartTitle')

    const chartSetting = {
      type: chartType,
      data: {
        labels: [],
        datasets: []
      },
      options: options
    }

    const myChart = new ChartJs($ctx.get(0), chartSetting)

    myChart.data.labels = chartData.labels
    myChart.data.datasets = chartData.datasets // or you can iterate for multiple datasets
    myChart.update() // finally update our chart
  }

  getOptionsForType (optionType) {
    let options = {
      title: {
        display: false
      },
      tooltips: {
        mode: 'index',
        intersect: true
      },
      responsive: true,
      maintainAspectRatio: false
    }

    switch (optionType) {
      case 'stackedBar':
      case 'stackedLine':
      case 'filledStackedLine':
        options.scales = {
          xAxes: [{
            stacked: true
          }],
          yAxes: [{
            stacked: true
          }]
        }
    }

    switch (optionType) {
      case 'bar':
      case 'stackedBar':
        break
      case 'line':
      case 'stackedLine':
        options.elements = {
          point: {
            radius: 3
          }
        }
        break
      case 'filledLine':
      case 'filledStackedLine':
        options.plugins = {
          filler: {
            propagate: false
          },
          'samples-filler-analyser': {
            target: 'chart-analyser'
          }
        }
        break
    }

    return options
  }
}
