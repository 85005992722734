import $ from 'jquery'

export default class resultEditIndex {
  constructor (element) {
    this.$ergebnisInData = $('#ergebnisInData', element)
    this.$ergebnis = $('#ergebnis', element)
    this.$ergebnisRow = this.$ergebnis.closest('.formRow')
    this.$ergebnisInData.on('change', this.handleErgebnisInDataChange.bind(this))
    this.handleErgebnisInDataChange()
  }

  handleErgebnisInDataChange (event) {
    const state = this.$ergebnisInData.prop('checked')
    this.$ergebnisRow.toggle(!state)
  }
}
