import $ from 'jquery'

export default class testTypenBatchSelect {
  constructor (element) {
    this.$checkbox = $(element)
    this.standortIds = this.$checkbox.data('standortIds')

    this.$checkbox.on('change', this.handleCheck.bind(this))
  }

  handleCheck (event) {
    if (!this.$checkbox.prop('checked')) {
      // aussteigen
    }

    // hier alle
    // this.standortIds - checkboxen suchen und anhaken
  }
}
