import $ from 'jquery'

export default class couponSelect {
  constructor (element) {
    this.$selectAllCoupons = $('input#select_all', element)

    this.$selectAllCoupons.click(function () {
      $("input[type='checkbox']").prop('checked', this.checked)
    })
  };
}
