import $ from 'jquery'
import JSONView from 'json-view'

$(function ($) {
  $("input[jQtype='json']").each(function () {
    const $field = $(this)

    let jsonValue = null
    try {
      jsonValue = JSON.parse($field.val())
    } catch (e) {
      // do nothing
    }

    if (jsonValue !== null) {
      $field.hide()

      const view = new JSONView('JSON-Data', jsonValue)
      view.expand()

      const $viewContainer = $('<div class="jsonView root"></div>')
      $viewContainer.append(view.dom)
      $field.after($viewContainer)
      $viewContainer.get(0).data = view.value

      // update field value when JSON changed!
      view.on('change', function (key, oldValue, newValue) {
        $field.val(JSON.stringify(view.value))
      })
    }
  })
})
