/* global cms_username */
import $ from 'jquery'

$(document).ready(function () {
  $('.contentList tr[data-href] td').on('click', function () {
    if (!$(this).find('a').length) {
      let target = $(this).parent().data('href')
      if ($(this).data('href')) {
        target += $(this).data('href')
      }
      window.location = target
    }
  })
})

// eslint-disable-next-line no-unused-vars
function crmAddHistory (target) {
  var hiNewEntry = ''
  var dt = new Date()
  hiNewEntry += $.datepicker.formatDate('dd.mm.yy', dt) + ' ' + dt.toLocaleTimeString('de-DE', { hour12: false }) + '; '
  // eslint-disable-next-line camelcase
  hiNewEntry += cms_username + '; '
  hiNewEntry += '\n'
  $(target).prepend(hiNewEntry)
}

/*
function customCleanup (type, value) {
  switch (type) {
    case 'get_from_editor':
    case 'insert_to_editor':
    case 'submit_content':
      value = value.replace('www.stadtleben.de', 'stadtleben.de')
      break

    case 'get_from_editor_dom':
    case 'insert_to_editor_dom':
    case 'setup_content_dom':
    case 'submit_content_dom':
  }

  return value
}
*/
