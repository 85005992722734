import $ from 'jquery'
window.jQuery = $
window.$ = $

require('jquery-ui/ui/widgets/button')
require('jquery-ui/ui/widgets/slider')

require('jquery-ui/ui/widgets/datepicker')
require('jquery-ui/ui/i18n/datepicker-de')

require('jquery-ui-timepicker-addon/dist/jquery-ui-timepicker-addon')
require('jquery-ui-timepicker-addon/dist/i18n/jquery-ui-timepicker-de')

$(function ($) {
  $("input[jQtype='date']").datepicker({
    regional: 'de',
    showWeek: true,
    changeYear: true,
    changeMonth: true
  })

  $("input[jQtype='datetime']").datetimepicker({
    regional: 'de',
    timeFormat: 'HH:mm:ss',
    changeYear: true,
    changeMonth: true,
    showWeek: true,
    showHour: true,
    showSecond: true,
    showMillisec: false,
    showMicrosec: false
  })
})
