/* global tinymce */
import $ from 'jquery'
import 'tinymce'

$(document).ready(function () {
  require('tinymce/jquery.tinymce.min.js')

  $('.mceEditor').each(function () {
    const $editor = $(this)

    $editor.tinymce({
      setup: function (editor) {
        editor.on('change', function () {
          tinymce.triggerSave()
        })
      },
      theme_url: '/dist/tinymce-theme/theme.min.js',
      language_url: '/dist/tinymce-langs/de.js',
      body_class: 'mceContent',

      autoresize: true,

      language: 'de',

      content_css: window.cms_content_css + '?' + new Date().getTime(),

      plugins:
                'autolink anchor link image media table hr charmap emoticons lists advlist code print preview fullscreen contextmenu template autosave paste searchreplace wordcount emoticons',
      // 'pro: powerpaste spellchecker mediaembed' +

      relative_urls: false,
      convert_urls: false,
      entity_encoding: 'raw',

      menu: {
        edit: { title: 'Edit', items: 'undo redo | cut copy paste pastetext | searchreplace | selectall' },
        view: { title: 'View', items: 'visualaid | code preview fullscreen | print' },
        insert: { title: 'Insert', items: 'image media link anchor | charmap | template' }, // template hr
        format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript | formats | removeformat' },

        table: { title: 'Table', items: 'inserttable tableprops deletetable | cell row column' }
        // tools: {title: 'Tools', items: 'spellchecker'}
      },

      // Toolbar
      toolbar:
                'styleselect | ' +
                'bold italic underline removeformat  | ' +

                'link unlink anchor | ' +

                'bullist numlist | ' +

                'emoticons image ' +
                'media ' +
                'template ' +
                '| ' +

                'undo redo | ' +
                'restoredraft | ' +
                'code fullscreen',

      media_alt_source: false,
      media_dimensions: false,
      media_poster: false,
      media_url_resolver: function (data, resolve/*, reject */) {
        var embedHtml = '<iframe src="' + data.url + '"></iframe>'
        resolve({ html: embedHtml })
      },

      // Templates
      templates: '/tools/cms/_settings/templates.php',

      // HTML Options
      forced_root_block: false,
      valid_elements:
                'h1,h2,h3,h4,h5,h6,' +
                'p,' +
                'br,' +
                'strong/b,' +
                'em/i,' +
                'u,' +
                'img[id|class|title|alt|src|width|height|align|usemap|vspace|hspace|style],' +
                'figure[id|class],figcaption,' +
                'a[id|class|rel|title|href|name|target],' +
                'map[name|id],' +
                'area[shape|coords|href|target|title],' +
                'ol[style],ul[style],li,blockquote,' +
                'table[width|height|border|align|bgcolor|cellpadding|cellspacing|style],' +
                'th,tr,' +
                'td[style|width|height|border|align|valign|bgcolor|colspan|rowspan],' +
                'sub,sup,small,' +
                'div[style],' +
                'iframe[*]' +
                '',

      // Images Options
      image_description: true,
      image_title: true,
      image_caption: true,
      image_dimensions: true,
      image_advtab: true,
      // image_prepend_url: "https://www.tinymce.com/images/"

      // Paste Options
      paste_data_images: false,
      paste_as_text: false,
      paste_word_valid_elements: 'a,b,strong,i,em,h1,h2,h3',

      // Link Options
      link_title: true,
      link_context_toolbar: true,
      link_assume_external_targets: true,
      // link_assume_external_targets: 'https',
      link_default_protocol: 'https',

      default_link_target: '_blank',

      link_class_list: [
        { title: 'Keine Angabe', value: '' },
        { title: 'Button', value: 'button' }
      ],

      rel_list: [
        { title: 'Kein Angabe', value: '' },
        { title: 'nofollow', value: 'nofollow' },
        { title: 'noopener', value: 'noopener' },
        { title: 'noreferrer', value: 'noreferrer' },
        { title: 'Lightbox - Einzelbild', value: 'lightbox' },
        { title: 'Lightbox - Bildfolge', value: 'lightbox[]' }
      ],

      // Editor Options
      plugin_preview_height: 720,
      plugin_preview_width: 770,
      autosave_ask_before_unload: true,
      width: 820,
      max_width: 820,
      branding: false

    })
  })
})
