import $ from 'jquery'

export default class login {
  constructor (element) {
    this.$page = $(element)

    this.$loginForm = $('form.loginform', element)
    this.$loginSpinnerContainer = $('.loginSpinnerContainer', element)
    this.$loginForm.on('submit', this.handleLoginSubmit.bind(this))
  }

  handleLoginSubmit (event) {
    this.$loginSpinnerContainer.addClass('active')
    this.$loginForm.find('input').blur()
  }
}
