import $ from 'jquery'
require('emojionearea')

$(function ($) {
  $('.emoji').emojioneArea({
    pickerPosition: 'right',
    filtersPosition: 'bottom',
    tonesStyle: 'bullet',
    standalone: true,
    autocomplete: false
  })

  $('.emojiOnly').emojioneArea({
    pickerPosition: 'right',
    filtersPosition: 'bottom',
    tonesStyle: 'bullet'
  })

  $('.emojiText').emojioneArea({
    pickerPosition: 'right',
    filtersPosition: 'bottom',
    tonesStyle: 'bullet'
  })

  $('.emojiMemo').emojioneArea({
    pickerPosition: 'right',
    filtersPosition: 'bottom',
    tonesStyle: 'bullet'
  })
})
